// Cards / Accordion


//
// Base styles
//

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    word-wrap: break-word;
    background-color: $card-bg;
    background-clip: border-box;
    box-shadow: $card-box-shadow;
}

.card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    // Workaround for the image size bug in IE
    // See: https://github.com/twbs/bootstrap/pull/28855
    min-height: 1px;
    padding: 0 $card-spacer-x $card-spacer-y $card-spacer-x;

    @include media-breakpoint-up(lg) {
        padding: 0 $card-spacer-x-lg $card-spacer-y $card-spacer-x-lg;

    }

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

//
// Accordion
//

.accordion {
    overflow-anchor: none;
}

.accordion-item {
    &.card {
        overflow: hidden;
        border-radius: 1.25rem;
    }
}

.accordion-item + .accordion-item {
    margin-top: 0.5rem;

    @include media-breakpoint-up(lg) {
        margin-top: 0.875rem;
    }
}

.accordion-header {
    padding: 0;
    .btn-link {
        position: relative;
        width: 100%;
        padding: $card-spacer-y calc(#{$card-spacer-x} + 1em) $card-spacer-y $card-spacer-x;
        text-align: left;
        color: $body-color;
        font-size: 1rem;

        &:hover,
        &:focus {
            text-decoration: none;
            box-shadow: none;
            color: darken($body-color, 15%);
        }

        @include media-breakpoint-up(lg) {
            padding: $card-spacer-y calc(#{$card-spacer-x-lg} + 2em) $card-spacer-y $card-spacer-x-lg;
        }
    }

    .accordion-header-icon {
        position: absolute;
        height: 0.5em;
        width: 0.5em;
        top: 50%;
        right: $card-spacer-x;
        border-top: 2px solid;
        border-left: 2px solid;
        transform: translate(0, -50%) rotate(45deg);
        transition: transform 0.2s;

        @include media-breakpoint-up(lg) {
            height: 0.625em;
            width: 0.625em;
            right: $card-spacer-x-lg;
        }
    }

    .btn-link.collapsed {
        .accordion-header-icon {
            transform: translate(0, -50%) rotate(225deg);
        }
    }
}

@include media-breakpoint-down(sm) {
    .accordion {
        > .row > .col-12:not(:first-child){
            margin-top: 0.5rem;
        }
    }
}
